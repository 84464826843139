import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
   public wut = 'calculatorsalarii';
  show_sarakie = false;
  public model;

  brut_changed(event) {
    this.show_sarakie = (this.model < 5000);
    console.log(this.model);
  }

}
